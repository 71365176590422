<template>
  <div class="container">
    <div>
      <el-button type="primary" @click="add()">新增标签</el-button>
      <div style="float: right">
        <div class="search">
          <div style="float: right">
            <el-input placeholder="请输入标签名称" v-model="queryParams.searchName">
              <el-button slot="append" icon="el-icon-search" type="primary" @click="searchList">搜索</el-button>
            </el-input>
          </div>
        </div>
      </div>
    </div>
    <el-table :data="tableData" tooltip-effect="dark" style="width: 100%;margin-top:10px">
      <el-table-column width="55" type="selection" align="center"></el-table-column>
      <el-table-column prop="name" label="标签名称"></el-table-column>
      <el-table-column prop="goodstotal" label="商品数量"></el-table-column>
      <el-table-column prop="desc" label="标签说明"></el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editLabel(scope.row)">编辑</el-button>
          <el-button type="text" size="small" @click="delLabel(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <div class="delbtn">
        <el-button type="info" plain>删除标签</el-button>删除勾选的标签，且移除商品与该标签关系及标签页面
      </div>
      <Pagination :pageInfo="pageInfo" @refreshPageSize="refreshPageSize" @refreshPageNum="refreshPageNum"></Pagination>
    </div>
    <el-dialog :title="title" :visible.sync="addVisible" width="30%" :before-close="handleClose">
      <el-form ref="form" v-bind:rules="rules" v-bind:model="labelInfo" label-position="right" v-loading.body="loading"
        label-suffix=":" label-width="100px" size="large" element-loading-text="上传中">
        <el-form-item label="标签名称" prop="name">
          <el-input placeholder="请输入标签名称" v-model="labelInfo.name"></el-input>
        </el-form-item>

        <el-form-item label="标签说明" prop="desc">
          <el-input placeholder="标签说明" type="textarea" v-model="labelInfo.desc" style="width:200px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="onSubmit">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination';
  import { request } from '@/common/request';
  import { deal } from '@/common/main';
  export default {
    name: 'goodsLabel',
    components: {
      Pagination
    },
    data() {
      return {
        tableData: [],
        checked: false,
        addVisible: false,
        radio: '1',
        id: '',
        queryParams: {
          searchName: '',
          page: 1,
          pagesize: 10,

        },
        labelInfo: {},
        pageInfo: [],
        title: '新增标签',
        loading: false,
        rules: {
          name: [{ required: true, message: '标签名称不能为空', trigger: 'blur' }],
          desc: [{ required: true, message: '标签说明不能为空', trigger: 'blur' }]
        },
        searchName: ''
      };
    },
    created() {
      this.getLabelList();
    },
    methods: {
      refreshPageSize(val) {
        //刷新pageSize事件
        this.queryParams.pagesize = val;
        this.getLabelList();
      },
      refreshPageNum(val) {
        // 刷新pageNum事件
        this.queryParams.page = val;
        this.getLabelList();
      },
      //取消添加标签
      handleClose() {
        this.addVisible = false;
        this.labelInfo = {};
        this.title = '新增标签';
      },
      //添加标签
      add() {
        this.addVisible = true;
      },
      //获取标签列表
      getLabelList() {

        request.post('/goods/tag/list', this.queryParams).then(ret => {
          if (ret.code == 200) {
            this.pageInfo = ret.data;

            this.tableData = ret.data.data;
          } else {
            this.$message.error(ret.msg);
          }
        });
      },
      //编辑标签
      editLabel(e) {
        this.addVisible = true;
        this.labelInfo = e;
        this.title = '修改标签';
      },
      //删除标签
      delLabel(val) {
        var delIds = [];
        delIds.push(val);
        request.post('/goods/tag/delete', delIds).then(ret => {
          if (ret.code == 200) {
            this.$message.success(ret.msg);
            this.getLabelList();
          } else {
            this.$message.error(ret.msg);
          }
        });
      },
      //确定修改或添加
      onSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            request.post('/goods/tag/add', this.labelInfo).then(ret => {
              this.addVisible = false;
              this.title = '新增标签';
              if (ret.code == 200) {
                this.$message.success(ret.msg);
                this.labelInfo = {};
                this.getLabelList();
              } else {
                this.$message.error(ret.msg);
              }
            });
          } else {
            // console.log('error submit!!');
            return false;
          }
        });
      },
      //搜索
      searchList() {
        this.getLabelList();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .pagination {
    text-align: right;
    margin-top: 10px;
    overflow: hidden;

    .delbtn {
      float: left;
      color: #666;
      font-size: 12px;
    }
  }
</style>